


























































































































import Vue from "vue";
import Routes from "@/constants/routes/paths";
import Access from "@/constants/access";

export default Vue.extend({
  name: "MenuMobile",

  props: {
    showNotifications: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      access: Access,
      routes: Routes,
      drivers: 0
    };
  },

  computed: {
    appVersion(): string {
      return this.$store.getters.appVersion;
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
    hasReadTransactionAccess(): boolean {
      return (
        Access.transactions.HAS_ACCESS_READ_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS() ||
        Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS()
      );
    }
  },

  methods: {
    currentView() {
      const path = this.$route.path.slice(1);
      switch (path) {
        case this.routes.SERVICE_CENTER:
          return "zubut";
        case this.routes.DRIVERS:
        case this.routes.CLIENTS:
        case this.routes.CLIENT_CREATE:
        case this.routes.ADMINS:
        case this.routes.ADMINS_CREATE:
          return "user";
        case this.routes.SERVICES:
          return "history";
        case this.routes.STATISTICS:
          return "statistics";
        case this.routes.TRANSACTIONS:
          return "payments";
        case this.routes.INVOICES:
          return "invoices";
        case this.routes.WALLET:
          return "wallet";
        case this.routes.LOGS:
          return "logs";
      }
      return "zubut";
    }
  }
});
